
import * as cloudflareRuntime$LKaC0ICDYu from '/home/vsts/work/1/s/frontend/app/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "cloudflare",
  "domains": [
    "cdn.dev.vanderven.de",
    "cdn.vanderven.de",
    "cdn.staging.vanderven.de"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$LKaC0ICDYu, defaults: {"baseURL":"https://www.vanderven.de/"} }
}
        